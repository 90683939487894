<template>
    <div class="page-container">
        <md-app md-mode="reveal">

            <md-app-toolbar class="md-primary" :class="{'u-sudomode': sudoMode}">

                <md-button class="md-icon-button navigation-btn" @click="menuVisible = !menuVisible">
                    <md-icon>menu</md-icon>
                </md-button>

                <div class="app-content toolbar-content">

                    <img srcset="@/assets/img/tcs_logo_1x.png 1x, @/assets/img/tcs_logo_2x.png 2x" alt="Logo image"
                         class="app-logo">

                    <div class="toolbar-menu">
                        <span class="md-title">{{user ? `Hello, ${user.users_first} ${user.users_last}` : ''}}</span>
                        <md-icon class="icon-btn md-primary u-color-text-primary" @click.native="goToScanner">qr_code</md-icon>
                        <md-icon class="icon-btn md-primary u-color-text-primary">notifications_none</md-icon>
                        <md-icon class="icon-btn md-primary u-color-text-primary">person_outline</md-icon>
                        <md-icon class="icon-btn md-primary u-color-text-primary" @click.native="logOut()">exit_to_app
                        </md-icon>
                    </div>

                </div>


            </md-app-toolbar>

            <md-app-drawer :md-active.sync="menuVisible">

                <md-toolbar class="navigation-toolbar md-primary" md-elevation="0" :class="{'u-sudomode': sudoMode}">
                    <img srcset="@/assets/img/tcs_logo_1x.png 1x, @/assets/img/tcs_logo_2x.png 2x" alt=""
                         class="app-logo">
                    <span class="md-title">TCS</span>
                </md-toolbar>

                <md-list>
                    <router-link
                            v-if="
                            userRole === r.DESIGNER ||
                            userRole === r.WF_PRINTER ||
                            userRole === r.ADMIN ||
                            userRole === r.ACC_EXECUTIVE ||
                            userRole === r.MANAGER
                        "
                            to="/design"
                            tag="md-list-item"
                            @click.native="menuVisible = !menuVisible">
                        <md-icon>add_photo_alternate</md-icon>
                        <span class="md-list-item-text">Design</span>
                    </router-link>

                    <router-link
                            v-if="
                            userRole === r.FACILITATOR ||
                            userRole === r.ADMIN ||
                            userRole === r.ACC_EXECUTIVE ||
                            userRole === r.MANAGER ||
                            userRole === r.FACILITATOR ||
                            userRole === r.WF_PRINTER
                        "
                            to="/material"
                            tag="md-list-item"
                            @click.native="menuVisible = !menuVisible">
                        <md-icon>style</md-icon>
                        <span class="md-list-item-text">Material</span>
                    </router-link>

					<router-link
						v-if="
							userRole === r.INSTALLER ||
							userRole === r.ADMIN ||
							userRole === r.ACC_EXECUTIVE ||
							userRole === r.MANAGER ||
							userRole === r.PRODUCT_MANAGER
						"
						to="/shipments"
						tag="md-list-item"
						@click.native="menuVisible = !menuVisible">
						<md-icon>local_shipping</md-icon>
						<span class="md-list-item-text">Shipment</span>
					</router-link>

                    <router-link
                            v-if="
                            userRole === r.FACILITATOR ||
                            userRole === r.ADMIN"
                            to="/reports"
                            tag="md-list-item"
                            @click.native="menuVisible = !menuVisible">
                        <md-icon>style</md-icon>
                        <span class="md-list-item-text">Reports</span>
                    </router-link>

                    <md-menu md-size="small" :mdCloseOnSelect="true" :mdCloseOnClick="true" :md-offset-x="300" :md-offset-y="-36">
                        <li data-v-27db4f62="" class="md-list-item router-link-exact-active" md-menu-trigger>
                            <button type="button" class="md-list-item-button md-list-item-container md-button-clean">
                                <div class="md-list-item-content md-ripple"><i data-v-27db4f62=""
                                                                               class="md-icon md-icon-font md-theme-default">build</i><span
                                        data-v-27db4f62="" class="md-list-item-text">Installations</span></div>
                            </button>
                        </li>
                        <!--                        <router-link md-menu-trigger-->
                        <!--                                     v-if="-->
                        <!--                            userRole === r.INSTALLER ||-->
                        <!--                            userRole === r.FACILITATOR ||-->
                        <!--                            userRole === r.ADMIN ||-->
                        <!--                            userRole === r.ACC_EXECUTIVE ||-->
                        <!--                            userRole === r.MANAGER ||-->
                        <!--                            userRole === r.PRODUCT_MANAGER ||-->
                        <!--                            userRole === r.WF_PRINTER-->
                        <!--                        "-->
                        <!--                                     to="" tag="md-list-item">-->
                        <!--                            <md-icon>build</md-icon>-->
                        <!--                            <span class="md-list-item-text">Installations</span>-->
                        <!--                        </router-link>-->

                        <md-menu-content>
                            <md-list>

                                <!--<router-link-->
                                <!--to="/installer"-->
                                <!--tag="md-list-item" -->
                                <!--@click.native="hideDropdownFunction()">-->
                                <!--<md-icon>remove_circle</md-icon>-->
                                <!--<span class="md-list-item-text dropdown-list">Installation Dashboard</span>-->

                                <!--</router-link>-->

                                <router-link
                                        to="/ready-to-install"
                                        tag="md-list-item"
                                        @click.native="hideDropdownFunction()">
                                    <md-icon>remove_circle</md-icon>
                                    <span class="md-list-item-text dropdown-list">Ready to Install</span>
                                </router-link>

                                <router-link
                                        to="/installation-dashboard"
                                        tag="md-list-item"
                                        @click.native="hideDropdownFunction()">

                                    <md-icon>remove_circle</md-icon>
                                    <span class="md-list-item-text dropdown-list">Installation Dashboard</span>
                                </router-link>


                                <router-link to="/installer-groups" tag="md-list-item" @click.native="menuVisible = !menuVisible">
                                    <md-icon>remove_circle</md-icon>
                                    <span class="md-list-item-text dropdown-list">Installation Groups</span>
                                </router-link>
                                <router-link to="/installer-tickets-list" tag="md-list-item" @click.native="menuVisible = !menuVisible">
                                    <md-icon>remove_circle</md-icon>
                                    <span class="md-list-item-text dropdown-list">Installation Tickets</span>
                                </router-link>
                                <router-link to="/installer-walkthrough-list" tag="md-list-item" @click.native="menuVisible = !menuVisible">
                                    <md-icon>remove_circle</md-icon>
                                    <span class="md-list-item-text dropdown-list">Installation Walkthrough List</span>
                                </router-link>

                            </md-list>
                        </md-menu-content>
                    </md-menu>

                    <router-link
                            v-if="
                            userRole === r.ADMIN ||
                            userRole === r.ACC_EXECUTIVE ||
                            userRole === r.MANAGER ||
                            userRole === r.PRODUCT_MANAGER
                        "
                            to="/user-management"
                            tag="md-list-item"
                            @click.native="menuVisible = !menuVisible">
                        <md-icon>supervised_user_circle</md-icon>
                        <span class="md-list-item-text">Users</span>
                    </router-link>


                    <router-link
                            v-if="
                            userRole === r.ADMIN ||
                            userRole === r.ACC_EXECUTIVE ||
                            userRole === r.MANAGER ||
                            userRole === r.PRODUCT_MANAGER
                        "
                            to="/user-settings"
                            tag="md-list-item"
                            @click.native="menuVisible = !menuVisible">
                        <md-icon>settings</md-icon>
                        <span class="md-list-item-text">User Settings</span>
                    </router-link>

                </md-list>
            </md-app-drawer>

            <md-app-content>
                <div class="app-content">
                    <slot/>
                </div>
            </md-app-content>

        </md-app>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>
            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Got it</md-button>
        </md-snackbar>

    </div>
</template>

<script>
	import {roles} from '@/constants'

	export default {
		name: 'TheLayoutWrap',
		data: () => ({
			menuVisible: false,
			hideDropdown: true,
			r: roles,
			snackbar: {
				show: false,
				message: '',
				duration: 4000
			},
		}),
		computed: {
			user() {
				return this.$store.getters['auth/getCurrentUser'];
			},
			userRole() {
				return this.$store.getters['auth/getCurrentUser'] ? this.$store.getters['auth/getCurrentUser'].users_role : '';
			},
			sudoMode() {
				return this.$store.getters['auth/isSudoMode'];
			}
		},
		methods: {
            goToScanner(){
                this.$router.push('/shipments-scanner')
            },

			showSnackBar(message) {
				this.snackbar.message = message;
				this.snackbar.show = true;
			},

			hideDropdownFunction() {
				this.menuVisible = !this.menuVisible;
				this.hideDropdown = true;
			},

			logOut() {
				this.$store.dispatch('auth/signOut')
					.then((res) => {
						if (res) {
							this.showSnackBar(`You are back to your usual self ${res.users_first + ' ' + res.users_last}`);
						}
						this.$router.push('/login');
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
    .md-app {
        min-height: 100vh;
    }

    .md-drawer {
        width: 280px;
        max-width: calc(100vw - 125px);
    }

    .navigation-btn {
        position: absolute;
    }

    .md-app-content {
        padding: 16px 0;
    }

    .app-content {
		width: 100%;
        margin: 0 auto;

		&.toolbar-content {
			width: $container-width;
		}

        @include respond('medium') {
            width: 960px;
            margin: 0 auto;
        }
        @include respond('small') {
            width: 600px;
        }
        @include respond('xsmall') {
            width: 100%;
        }

        .app-logo {
            height: 45px;

            @include respond('small') {
                /*margin-left: 2.4rem;*/
            }
        }
    }

    .toolbar-content {
        display: flex;
        align-items: center;
        height: 64px;

        img {
            @include respond('xsmall') {
                display: none;
            }
        }
    }

    .toolbar-menu {
        margin-left: auto;

        .md-title {
            line-height: 3.6rem;
            margin-right: 6.4rem;

            @include respond('xsmall') {
                display: none;
            }
        }
    }

    .icon-btn {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $color-white;
        margin-left: 2.4rem;
        cursor: pointer;
        transition: all .1s ease-in;

        &:hover {
            box-shadow: 0 1rem 2.4rem rgba($color-white, .3);
        }

        &:active {
            transform: translateY(2px);
        }
    }

    .navigation-toolbar {
        .app-logo {
            height: 36px;
            margin-right: 12px;

            @include respond('xsmall') {
                display: none!important;
            }
        }

        .md-title {
            color: $color-white;
            font-weight: 600;
        }
    }

    .dropdown-list {
        font-size: 0.9em;
    }
</style>