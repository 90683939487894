<template>
    <div class="component-wrap">

        <AppContentHeader headline="Design Orders">
            <AppInput
                    class="filter-orders__field"
                    required
                    placeholder="Filter by any option"
                    v-model="filter"
            />
        </AppContentHeader>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <transition mode="out-in" name="fade">

            <md-tabs class="design-orders-tabs" v-show="!loadingSpinner">

                <md-tab id="tab-sponsorships" md-label="Sponsorships" @click="clickCurrentTab(1)">
                    <div class="md-layout md-alignment-top-center">

                        <OrderCard
                                v-for="order in filteredSponsorships"
                                :key="order.sponsorships_id + '_sponsorships'"
                                :orderData="order"
                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
                        </OrderCard>

                    </div>

                    <AppEmptyState message="There is no sponsorships to display." v-if="!sponsorships.length && !dontShowMsg"/>

                </md-tab>

                <md-tab id="tab-free-standing" md-label="Free Standing" @click="clickCurrentTab(2)">
                    <div class="md-layout md-alignment-top-center">

                        <OrderCard
                                v-for="(order, index) in freeStanding"
                                :key="index + '_freeStanding'"
                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
                        </OrderCard>

                    </div>


                    <AppEmptyState message="There is no free standing orders to display." v-if="!freeStanding.length && !dontShowMsg"/>

                </md-tab>

                <md-tab id="tab-signage" md-label="Signage"  @click="clickCurrentTab(3)">

                    <div class="md-layout md-alignment-top-center">

                        <OrderCard
                                v-for="order in filteredSignage"
                                :key="order.id + '_signage'"
                                :orderData="order"
                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
                        </OrderCard>

                    </div>

                    <AppEmptyState message="There is no free signage to display." v-if="!signage.length && !dontShowMsg"/>

                </md-tab>

            </md-tabs>
        </transition>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->

    </div>
</template>

<script>
import OrderCard from "@/components/Common/OrderCard"
import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"


export default {
    name: 'DesignDashboard',
    mixins: [DeepFilterMixin],
    data: function() {
        return {
            dontShowMsg: false,
            sponsorships: [],
            freeStanding: [],
            signage: [],
            tabNumber: 1,
            loadingSpinner: false,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            filter: ''
        }
    },
    components: {
        OrderCard
    },
    created() {
        this.loadData(1);
        this.getMaterials();
        this.filter = this.$store.getters['getSignageFilter'];
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('saveSignageFilter', this.filter);
        next();
    },
    computed: {
        filteredSponsorships() {
            return this.sponsorships.filter(x => this.filterFunction(x, this.filter));
        },
        filteredSignage() {
            return this.signage.filter(x => this.filterFunction(x, this.filter));
        }
    },
    methods: {
        clickCurrentTab(tabNumber) {
            this.dontShowMsg = true;
            this.loadData(tabNumber);
        },
        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },

        async loadData(tabNumber) {

            this.dontShowMsg = true;

            try {
                if (tabNumber === 1) {

                    this.sponsorships = (await this.$axios.get('sponsorships')).data;
                    this.dontShowMsg = false;

                } else if (tabNumber === 2) {

                    // this.freeStanding = (await this.$axios.get('free-standing')).data;
                    this.freeStanding = [];
                    this.dontShowMsg = false;

                } else {

                     this.signage = (await this.$axios.get('signage-orders', { params: { current_season: true } })).data;
                    this.dontShowMsg = false;
                }

                this.loadingSpinner = false;

            } catch (err) {
                this.loadingSpinner = false;
                this.showSnackbar(err.message);
            }
        },

        getMaterials() {
            this.$store.dispatch('materials/getMaterials');
        }
    }
}
</script>

<style lang="scss" scoped>

    .design-orders-tabs {
        border: 1px solid rgba($color-black, .4);

        /deep/ .md-tabs-navigation {
            @include respond(xsmall) {
                flex-direction: column;
            }

            .md-button {
                @include respond(small) {
                    width: 100%;
                    max-width: 100%;
                }
             }
        }
    }
</style>