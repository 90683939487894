<template>
    <div class="search__form md-layout md-gutter">

        <div class="md-layout md-layout-item md-alignment-top-space-between md-size-100 search-header">
            <h2 class="search__header md-layout-item md-size-10 md-medium-size-30 md-small-size-100">Search</h2>

            <div class="search md-content md-layout-item md-size-60 md-medium-size-40 md-small-size-100">
                <div class="search__form__custom-layout md-layout-item md-size-40 md-medium-size-53 md-small-size-100">
                    <AppInput class="search__form__custom md-layout-item" v-model="searchStr" placeholder="Search"/>
                </div>
            </div>

            <div class="md-layout-item md-layout md-size-20 md-medium-size-30 md-small-size-100 previous-season-toggle">
                <span
                    class="toggle-label md-layout-item md-small-size-100 "><strong>Show previous season orders: </strong></span>

                <toggle-button
                    class="md-layout-item md-small-size-100 prev-btn1"
                    v-model="previous_season"
                    color="#CD0A2F"
                    :width=80
                    :font-size=17
                    :height=35
                    :labels="{checked: 'On', unchecked: 'Off'}"/>

              <span
                  class="toggle-label md-layout-item md-small-size-100 "><strong>Show cancelled orders: </strong></span>

              <toggle-button
                  class="md-layout-item md-small-size-100 "
                  v-model="cancelled"
                  color="#CD0A2F"
                  :width=80
                  :font-size=17
                  :height=35
                  :labels="{checked: 'On', unchecked: 'Off'}"/>
            </div>
        </div>

        <div
            class="dropdown-select-search md-layout-item md-size-12 md-medium-size-33 md-small-size-100 md-small-size-100">
            <div class="md-field ">
                <label>Order Type</label>

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="filters.media_type">

                    <option value="sponsorship"> Sponsorship</option>
                    <option value="signage"> Operational Signage</option>

                </AppInputSelectDropdown>
            </div>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-33 md-small-size-100"
             v-if="filters.media_type === 'sponsorship'">
            <md-field>
                <label>Item Type</label>

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="product_id">

                    <option v-for="(itemType, index) in itemTypeOptions" :key="index" :value="itemType.products_id">
                        {{ itemType.products_name }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-33 md-small-size-100">
            <md-field class="">
                <label>Building</label>

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="filters.building_id">
                    <option :value="''"></option>

                    <option v-for="(building, index) in buildings" :key="index" :value="building.id">
                        {{ building.name }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>


        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-33 md-small-size-100">
            <md-field class="">
                <label>Disposition</label>

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="filters.end_market_disposition">
                    <option :value="''"></option>

                    <option v-for="(market, index) in markets" :key="index" :value="market">
                        {{ market }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-50 md-small-size-100">
            <md-field class="">
                <label>Floor</label>

                <AppInputSelectDropdown
                    v-if="filters.building_id"
                    class="create-form__field"
                    v-model="filters.floor">

                    <option :value="''"></option>
                    <option v-for="(floor, index) in selectedBuildingFloors" :key="index" :value="floor">
                        {{ floor }}
                    </option>

                </AppInputSelectDropdown>

            </md-field>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-50 md-small-size-100">
            <md-field class="">
                <label> Spaces </label>

                <AppInputSelectDropdown
                    v-if="isSponsorshipSelected == false"
                    class="create-form__field"
                    v-model="filters.adspace_id">
                    <option :value="''"></option>

                    <option v-for="(space, index) in adspacesSignageOptions" :key="index" :value="space.id">
                        {{ space.description | spaceSignage }}
                    </option>

                </AppInputSelectDropdown>

                <AppInputSelectDropdown
                    v-if="isSponsorshipSelected == true"
                    class="create-form__field"
                    v-model="filters.adspace_id">
                    <option :value="''"></option>
                    <option v-for="(space, index) in adspacesOptions" :key="index" :value="space.id">
                        {{ space.description }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-50 md-small-size-100">
            <md-field class="">
                <label>Status</label>

                <AppInputSelectDropdown
                    v-if="isSponsorshipSelected == false"
                    class="create-form__field"
                    v-model="filters.status_id">
                    <option :value="''"></option>

                    <option v-for="(status, index) in signageStatusesOptions" :key="index" :value="status.id">
                        {{ status.name }}
                    </option>

                </AppInputSelectDropdown>

                <AppInputSelectDropdown
                    v-if="isSponsorshipSelected == true"
                    class="create-form__field"
                    v-model="filters.status_id">
                    <option :value="''"></option>

                    <option v-for="(status, index) in sponsorshipStatusesOptions" :key="index" :value="status.id">
                        {{ status.name }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>

        <div class="dropdown-select-search md-layout-item md-size-12 md-medium-size-50 md-small-size-100">
            <md-field class="">
                <label>Material</label>

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="filters.material_id">
                    <option :value="''"></option>

                    <option v-for="(material, index) in materialOptions" :key="index" :value="material.id">
                        {{ material.name }}
                    </option>

                </AppInputSelectDropdown>
            </md-field>
        </div>

        <!--        <div class="search__form__custom-layout md-layout-item md-size-12 md-medium-size-33 md-small-size-100">-->
        <!--            <AppInput class="search__form__custom md-layout-item " placeholder="Custom Search"/>-->
        <!--        </div>-->

        <div class="button-search md-layout-item md-size-12 md-medium-size-100">
            <md-button class="desktop-search-button md-primary md-raised" @click="search(false)">
                Search
            </md-button>
        </div>

    </div>
</template>

<script>

import AppInputSelectDropdown from '@/components/Base/AppInputSelectDropdown';

export default {
    name: 'Search',
    components: {
        AppInputSelectDropdown,
    },

    data: function() {
        return {
            orders: [],
            loadingSpinner: false,
            sponsorshipStatusesOptions: [],
            signageStatusesOptions: [],
            adspacesOptions: [],
            adspacesSignageOptions: [],
            buildings: [],
            markets: [],
            searchStr: '',
            itemTypeOptions: [],
            materialOptions: [],

            showSidePanel: false,
            showTabletTopPanel: false,
            previous_season: false,
            cancelled: false,
            filters: {
                search: null,
                season: null,
                media_type: 'signage',
                building_id: null,
                end_market_disposition: null,
                floor: null,
                adspace_id: null,
                status_id: null,
                location: null,
                material_id: null,
            },
            product_id: null,

            snackbar: {
                show: false,
                message: '',
                duration: 4000,
            },
        };
    },

    filters: {

        spaceSignage: function(value) {

            if (value) {

                return value;
            }
            return 'No name';
        },
    },

    methods: {

        getLocations() {

            this.$axios.get('locations').then((response) => {

                this.locations = response.data;
            });
        },

        getSponsorshipStatuses() {

            this.$axios.get('sponsorship-statuses').then((response) => {

                let statuses = response.data;

                for (let i = 0; i < statuses.length; i++) {

                    this.sponsorshipStatusesOptions.push({
                        id: statuses[i].sponsorship_status_id,
                        name: statuses[i].sponsorship_status_name,
                        stage: statuses[i].sponsorship_status_stage,
                    });
                }
            });
        },

        getAdspaces() {

            this.$axios.get('adspaces').then((response) => {

                let statuses = response.data;

                for (let i = 0; i < statuses.length; i++) {

                    this.adspacesOptions.push({
                        id: statuses[i].adspaces_id,
                        description: statuses[i].adspaces_description,
                    });
                }
            });
        },

        getSignageStatuses() {

            this.$axios.get('signage-statuses').then((response) => {

                console.log(response.data);

                let statusArrayFiltered = [];

                for (let i = 0; i < response.data.length; i++) {

                    switch (response.data[i].id) {
                        case 1:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 2:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 3:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 7:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 26:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 8:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 16:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 9:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 19:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 10:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                        case 20:
                            statusArrayFiltered.push(response.data[i]);
                            break;
                    }
                }

                this.signageStatusesOptions = statusArrayFiltered;
            });
        },

        getSignageSpaces() {

            this.$axios.get('signage').then((response) => {

                this.adspacesSignageOptions = response.data;
            });
        },

        getBuildings() {

            this.$axios.get('buildings').then((response) => {

                this.buildings = response.data;
            });
        },

        getItemType() {

            this.$axios.get('products').then((response) => {
                this.itemTypeOptions = response.data;
            });
        },

        getMaterial() {

            this.$axios.get('materials').then((response) => {
                this.materialOptions = response.data;
            });
        },

        search(callRoute = true) {

            this.$emit('loadingSearchResults', true);

            let query = {
                media_type: this.filters.media_type,
                building_id: this.filters.building_id,
                floor: this.filters.floor,
                adspace_id: this.filters.adspace_id,
                status_id: this.filters.status_id,
                end_market_disposition: this.filters.end_market_disposition,
            };

          if (this.filters.media_type === 'signage') {

            if (this.previous_season) {

              if(this.cancelled){
                this.signageOrdersSearch({params: {...this.filters, previous_season: true, show_cancelled_orders: true}});
                query['previous_season'] = true;
                query['show_cancelled_orders'] = true;
              }
              else{
                this.signageOrdersSearch({params: {...this.filters, previous_season: true}});
                query['previous_season'] = true;
              }

            } else {

              if(this.cancelled) {

                this.signageOrdersSearch({params: {...this.filters, current_season: true, show_cancelled_orders: true}});
                query['current_season'] = true;
                query['show_cancelled_orders'] = true;
              }
              else{
                this.signageOrdersSearch({params: {...this.filters, current_season: true}});
                query['current_season'] = true;
              }
            }

          } else if (this.filters.media_type === 'sponsorship') {

            if (this.previous_season) {

              if(this.cancelled) {

                this.sponsorshipsSearch({params: {...this.filters, previous_season: true, show_cancelled_orders: true}});
                query['previous_season'] = true;
                query['show_cancelled_orders'] = true;

              }
              else{

                this.sponsorshipsSearch({params: {...this.filters, previous_season: true}});
                query['previous_season'] = true;
              }
            } else {

              if(this.cancelled) {
                this.sponsorshipsSearch({params: {...this.filters, show_cancelled_orders: true}});
                query['show_cancelled_orders'] = true;
              }
              else{
                this.sponsorshipsSearch({params: {...this.filters }});
              }
            }
          } else {

                this.$emit('loadingSearchResults', false);
            }

            if (callRoute) {

                this.$router.push({
                    path: 'installation-dashboard',
                    query: query,
                });
            }

            this.filters = {
                search: null,
                season: null,
                show_cancelled_orders: null,
                media_type: this.filters.media_type,
                building_id: null,
                floor: null,
                adspace_id: null,
                status_id: null,
                location: null,
                material_id: null,
                end_market_disposition: null,
            };

            this.product_id = null;
        },

        signageOrdersSearch(data) {

            this.$axios.get('signage-orders', data).then((response) => {

                this.$emit('setResultsOrders', response.data);
                this.$emit('loadingSearchResults', false);
            }).catch(() => {
                this.$emit('loadingSearchResults', false);
            });
        },

        sponsorshipsSearch(data) {

            this.$axios.get('sponsorships', data).then(response => {

                this.$emit('setResultsOrders', response.data);
                this.$emit('loadingSearchResults', false);
            }).catch(() => {
                this.$emit('loadingSearchResults', false);
            });
        },

        async loadData() {

            try {
                this.getLocations();
                this.getSignageStatuses();
                this.getSignageSpaces();
                this.getSponsorshipStatuses();
                this.getAdspaces();
                this.getBuildings();
                this.getItemType();
                this.getMaterial();

                this.markets = [
                    'leave_up',
                    'move_to_staging',
                    'trash',
                ];

            } catch (err) {

                this.showSnackbar(err.message);
            }
        },
    },

    created() {

        this.loadData();
        // this.filters = this.$store.getters['getVendorFilters'];
        this.orders = this.$store.getters['getVendorOrders'];

        let previousSeasonLocalStorageString = localStorage.getItem('previous_season');

        if (previousSeasonLocalStorageString === 'true') {
            this.previous_season = true;
        } else {
            this.previous_season = false;
        }

        if (this.$route.query.media_type === 'signage') {

            this.signageOrdersSearch({params: {...this.$route.query, current_season: true}});
        } else if (this.$route.query.media_type === 'sponsorship') {

            this.sponsorshipsSearch({params: this.$route.query});
        }
    },

    computed: {

        isSponsorshipSelected() {

            return this.filters.media_type !== 'signage';
        },

        selectedBuildingFloors() {

            let building = this.selectedBuilding;

            if (building) return building.floors;

            return null;
        },

        selectedMarket() {

            let selectedMarket = this.markets.find(obj => {
                return obj.id == this.filters.end_market_disposition;
            });

            if (selectedMarket) {

                return selectedMarket;
            }

            return null;
        },

        selectedBuilding() {

            let selectedBuilding = this.buildings.find(obj => {
                return obj.id == this.filters.building_id;
            });

            if (selectedBuilding) {

                return selectedBuilding;
            }

            return null;
        },
    },

    watch: {
        searchStr(value) {

            this.filters.search = value;

//            this.$emit('searchInput', value);
        },

        selectedBuilding(value) {

            if (value === null) {

                this.filters.building_id = null;
                this.filters.floor = null;
            }
        },

        previous_season(value) {
            localStorage.setItem('previous_season', value);
        },
    },
};
</script>

<style lang="scss" scoped>

.prev-btn1{
  margin-left: 30px;
}

h2 {
    font-weight: normal;
}

.search {

    &__header {
        font-size: 55px !important;
        line-height: 65px;
    }

    &__form {

        .search-header {

            padding-bottom: 30px;
        }

        select#spaces-signage {
            width: 100%;
        }

        .md-field {
            border: 1px solid black;
            box-sizing: border-box;
            height: 110px;
            padding-top: 0px !important;

            @include respond(large) {
                min-width: 140px;

            }

            @media only screen and (max-width: 1619px) and (min-width: 1287px) {
                max-width: 188px;
            }

            @include respond(small) {
                height: 50px;
                padding-top: 1px;
            }

            @media only screen and (min-width: 950px) {
                .md-menu.md-select {
                    margin-top: 15%;
                    margin-left: 2px;
                }
            }

            @media only screen and (max-width: 950px) {
                .md-menu.md-select {
                    margin-top: 15px;
                    margin-left: 2px;
                }
            }

            label {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                padding-left: 12px;
                color: black;
                font-size: 24px;
                line-height: 0px;

                @include respond(small) {
                    font-size: 14px;
                    top: 10px;
                }
            }
        }

        .desktop-search-button {
            height: 110px;
            background: rgba(231, 82, 82, 0.88) !important;
            width: 100%;

            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            color: black !important;

            @include respond(small) {
                height: 50px;
                padding-top: 1px;
                font-size: 16px;
            }
        }

        &__custom {
            @include respond(large) {
                min-width: 180px;
            }
        }

        .search {

            .md-field {
                position: static !important;
                margin-bottom: 0px;
                background-color: rgba($color-white, 1.0);
                border-radius: 50px;
                height: 35px !important;

                .md-input {
                    -webkit-text-fill-color: black !important;
                }

                .md-icon {
                    height: 90%;
                }

                input {
                    height: 100% !important;
                }

                label {
                    position: relative !important;
                    margin-left: 20px;
                }
            }
        }

        @include respond('small') {
            width: 60% !important;
        }
    }

}

::v-deep .control-field {
    margin: 4px 0 24px;
}

::v-deep .control-field__input {
    border: 1px solid black !important;
    box-sizing: border-box;
    margin-right: 20px;
    height: 110px;
    border-radius: 0px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-left: 5px !important;

    @include respond(small) {
        height: 50px;
        padding-top: 1px;
        font-size: 16px;
    }
}

.md-layout.md-gutter > .md-layout-item {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.previous-season-toggle {
    text-align: right;
    margin-bottom: 20px;

    .toggle-label {
        font-size: 1.2em;
    }
}

.search__form__custom-layout {
    margin-top: auto;
}

.search__form__custom {
    /deep/ input {
        border-radius: 20px !important;
        padding-left: 15px !important;
        height: 60px;
        margin-top: auto;
        margin-bottom: auto;
    }
}

</style>