<template>
    <div class="component-wrap">

        <AppContentHeader
            v-if="!loadingSpinner"
            :headline="materialName">
            <md-button class="md-primary md-raised"
                       @click.stop="printSet(sets[0][0].material_id)">Print set
            </md-button>
        </AppContentHeader>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>


        <div class="queues-slider">

            <div class="queue-control-panel md-layout-item md-large-size-100 md-elevation-10">

                <!-- <md-button class="md-primary md-raised"
                    :disabled="setIndex <= 0" 
                    @click="setIndex--">Previous</md-button> -->

                <!--<span class="md-body-2">{{setIndex !== (sets.length - 1) ? 'Previous set (Printed on 10/10/2019)' : 'Current set'}}</span>-->

                <!-- <md-button class="md-primary md-raised"
                            :disabled="setIndex >= (sets.length - 1)"
                            @click="setIndex++"
                            >Next</md-button> -->

            </div>

            <div class="md-layout md-alignment-top-space-between" :key="setIndex" v-if="!loadingSpinner">

                <OrderCard
                    v-for="(order, index) in sets[0]"
                    :key="index + '_orders'"
                    :orderData="order"
                    cardMode="minimal"
                    class="md-layout-item md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
                </OrderCard>

            </div>

        </div>

    </div>
</template>

<script>
import OrderCard from '@/components/Common/OrderCard.vue';

export default {
    name: 'MaterialDashboardOneQueue',
    data: () => ({
        sets: [],
        materialName: '',
        set: [],
        setIndex: 0,
        loadingSpinner: true,
    }),
    components: {
        OrderCard,
    },
    created() {
        this.getQueue();

        this.setIndex = this.sets.length - 1;
        this.getCurrentMaterial(this.$route.params.id);
    },
    methods: {
        printSet(id) {
            this.$axios.get(`reports/queues/export?material_id=${id}`).then(res => {
                window.open(res.data, '_blank');
            }).catch(err => {
                this.$emit('printFailed', err.message);
            });
        },
        getQueue() {
            this.loadingSpinner = true;
            this.$axios.get(`/queues/${this.$route.params.id}?ready_to_print=true`).then(res => {
                this.sets[0] = [...res.data['sponsorships'], ...res.data['signage_orders']];
                this.loadingSpinner = false;
            });
        },
        getCurrentMaterial(material_id) {

            this.$axios.get(`/materials/${material_id}`).then(res => {
                this.materialName = res.data.name;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.queues-slider {
    background-color: rgba($color-grey, .2);
}

.queue-control-panel {
    height: 56px;
    margin-bottom: $margin-medium;
    background-color: $color-white;
    display: flex;
    align-items: center;
    padding: 0 2.4rem;

    .md-body-2 {
        margin: 0 auto;

        @include respond(xsmall) {
            font-size: 1.2rem;
            font-weight: 400;
            text-align: center;
            line-height: 1.4rem;
        }
    }

    .md-button {
        @include respond(xsmall) {
            font-size: 1.2rem;
            font-weight: 400;
            padding: 0 1rem;
        }
    }
}
</style>