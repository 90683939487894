<template>
    <div class="card-container">
        <div class="card-container__header">
            <h2 class="main-hdr">Art ID: {{ installation.id }}</h2>
            <h4 class="second-hdr">{{ installation.statusName }}</h4>
        </div>

        <div class="md-layout card-container__body">
            <div
                class="md-layout-item md-xlarge-size-45 md-large-size-45 md-medium-size-50 md-small-size-100 containers-hdr left-container-hdr">
                <div class="md-layout">
                    <div class="md-layout-item">
                        <p class="first-paragraph"><strong>Location name / Description</strong></p>
                        <p>{{
                                installation.mediaType === 'signage' ?
                                    installation.spaceDescription :
                                    installation.locationName
                            }}</p>
                        <p class="first-paragraph" style="margin-top: 10px"><strong>Design Notes</strong></p>
                        <p style="margin-bottom: 10px" v-if="installation.mediaType === 'signage'">
                            {{installation.notes.notes}}
                        </p>

                        <p style="margin-bottom: 10px" v-if="installation.mediaType != 'signage'">
                            {{installation.notes}}
                        </p>
                    </div>
                </div>
                <div class="md-layout details-table">
                    <div class="md-layout-item md-large-size-100">
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr">Order ID:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info">{{ installation.id }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr gray-background">Adspace ID:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info gray-background">{{ installation.spaceId }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr">Adspace Description:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info">{{ installation.spaceDescription }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr">Location Code:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info">{{ installation.location_code }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr gray-background">Install By:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info gray-background">{{ installation.installBy }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr">Size:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info">{{ installation.size }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr gray-background">End of Market Disposition:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info gray-background">{{ installation.disposition }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr">Leave until:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info">{{ installation.leaveUntil }}</p>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-large-size-50 detail-container detail-labels-container">
                                <p class="details-hdr gray-background">Remove by:</p>
                            </div>
                            <div class="md-layout-item md-large-size-50 detail-container">
                                <p class="details-info gray-background">{{ installation.removeBy }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="installation.artworkThumbnail"
                 class="md-layout-item md-large-size-45 md-xlarge-size-45 md-small-size-60 containers right-container">
                <div class="right-container-hdr">
                    <div v-if="showThumbnail">
                        <p class="first-paragraph">Tap the thumbnail again to download a Hi-resolution version</p>
                    </div>
                </div>
                <div class="img-thumbnail-container" @click="showThumbnail = true">

                    <div class="missing-thumbnail" v-if="!showThumbnail">
                        <p> Tap to show low-res thumbnail </p>
                        <md-icon class="md-size-3x">cloud_download</md-icon>
                    </div>

                    <div v-if="showThumbnail">
                        <a :href=installation.artworkDownloadLink target="_blank">
                            <img v-lazy="installation.artworkThumbnail"
                                 alt="Installation Image"
                                 class="card-image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    name: 'InstallationDetailCard',
    props: {
        installation: {
            type: Object,
        },
    },

    data: function() {
        return {
            showThumbnail: false,
        };
    },
    created() {

        console.log(this.installation);
    },
    methods: {},

};
</script>

<style lang="scss" scoped>

.card-container {

    border-bottom: 1px solid gray;
    margin-bottom: 10px;

    &__header {
        text-align: center;

        .main-hdr {
            margin-top: 20px;
        }

        .second-hdr {
            margin-top: 10px;
            font-weight: 500 !important;
        }
    }

    &__body {

        margin-top: 20px;
        justify-content: space-around;

        .details-table {
            border: 1px solid black;
        }

        .containers {
            border: 1px solid gray;
            margin-bottom: 10px;
        }

        .left-container-hdr {
            text-align: left;
            margin-bottom: 11px;

            .first-paragraph {
                margin-bottom: 5px;
            }
        }

        .right-container-hdr {

            margin-bottom: 11px;
            color: #E0677D;
            text-align: center;
        }

        .details-hdr {
            height: 100%;
            font-weight: bold;
            padding-left: 10px;
        }

        .details-info {
            padding-left: 30px;
            color: black;
            min-height: 39.58px;
        }

        .detail-container {

            p {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .detail-labels-container {
            border-right: 1px solid black;
        }

        .gray-background {
            background-color: #C4C4C4;
        }

        .card-image {
            width: 100%;
        }
    }

    .img-thumbnail-container {

        height: 100%;

        .missing-thumbnail {
            padding-top: 15px;
            text-align: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #000000;

            .md-icon {
                padding-top: 22px;
            }
        }
    }
}


</style>