import Vue from 'vue';
import Router from 'vue-router';
import {store} from '../store/store';
import middlewarePipeline from './middlewarePipeline';

Vue.use(Router);

import AppLogin from '../AppLogin';

import AppContent from '../AppContent';

import ResetPasswordPage from '../pages/AuthPages/ResetPasswordPage';

import DesignDashboard from '../pages/DesignDashboard/DesignDashboard';
import DesignDashboardOrder
	from '../pages/DesignDashboard/DesignDashboardOrder.vue';

import MaterialDashboard from '../pages/MaterialDashboard/MaterialDashboard';
import MaterialDashboardQueuesList
	from '../pages/MaterialDashboard/MaterialDashboardQueuesList';
import MaterialDashboardOneQueue
	from '../pages/MaterialDashboard/MaterialDashboardOneQueue';
import MaterialDashboardManageMaterials
	from '../pages/MaterialDashboard/MaterialDashboardManageMaterials';

import ShipmentDashboard from '../pages/ShipmentDashboard/ShipmentDashboard';
import Order from '../pages/Order/Order';
import Shipments from '../pages/ShipmentDashboard/Shipments';
import ShipmentForm from '../pages/ShipmentDashboard/ShipmentForm';
import ShipmentScanner from '../pages/ShipmentDashboard/ShipmentScanner';

import ShipmentDashboardItemPage
	from '../pages/ShipmentDashboard/ShipmentDashboardItemPage';
import ShipmentDashboardBocPackingPage
	from '../pages/ShipmentDashboard/ShipmentDashboardBoxPackingPage';

import UserManagement from '../pages/UserManagement/UserManagement';
import UserSettings from '../pages/UserSettings';

import InstallerDashboard from '../pages/InstallerDashboard/InstallerDashboard';
import InstallerDashboardItemPage
	from '../pages/InstallerDashboard/InstallerDashboardItemPage';
import InstallerDashboardReadyToInstall
	from '../pages/InstallerDashboard/InstallerDashboardReadyToInstall';
import InstallerDashboardMainFeature
	from '../pages/InstallerDashboard/InstallerDashboardMainFeature';
import InstallerGroupList
	from '../pages/InstallerGroup/InstallationGroupList.vue';
import InstallerGroupCreate
	from '../pages/InstallerGroup/InstallationGroupCreate.vue';
import InstallerGroupEdit
	from '../pages/InstallerGroup/InstallationGroupEdit.vue';
import OrderGroupLocations
	from '../pages/InstallerGroup/OrderGroupLocations.vue';
import InstallerGroupLocations
	from '../pages/InstallerGroup/InstallationGroupLocations.vue';
import InstallerWalkthrough
	from '../pages/InstallerGroup/InstallationWalkthrough.vue';
import InstallerWalkthroughList
	from '../pages/InstallerGroup/InstallationWalkthroughList.vue';
import InstallerTicketsList
	from '../pages/InstallerGroup/InstallationTicketsList.vue';
import InstallerTicketBlock
	from '../pages/InstallerGroup/InstallationTicketBlock.vue';

import PageNotFound from '../pages/PageNotFound';

import ChooseCampusPage from '../pages/ChooseCampusPage';

import auth from './middleware/auth';
import guest from './middleware/guest';
import rolesCheck from './middleware/roles';
import domain from './middleware/domain';

import {roles} from '../constants';
import SingleShipment from "@/pages/ShipmentDashboard/SingleShipment";
import SingleShipmentBox from "../pages/ShipmentDashboard/SingleShipmentBox";
import ReportsDashboard from "@/pages/ReportsDashboard/ReportsDashboard";
import ReportsDashboardBilling from "@/pages/ReportsDashboard/ReportsDashboardBilling";
import ReportsDashboardBillingSignage from "@/pages/ReportsDashboard/ReportsDashboardBillingSignage";
import ReportsDashboardBillingSponsorships from "@/pages/ReportsDashboard/ReportsDashboardBillingSponsorships";

const router = new Router({
	mode: 'history',
	routes: [
		
		{
			path: '/login', component: AppLogin, name: 'login',
			meta: {
				middleware: [guest, domain],
			},
		},
		
		{
			path: '/reset/:hash',
			component: ResetPasswordPage,
			meta: {
				middleware: [domain],
			},
		},
		
		{path: '/choose-campus', component: ChooseCampusPage},
		
		{
			path: '', component: AppContent,
			meta: {
				middleware: [domain],
			},
			
			children: [
				{
					path: '', redirect: '/design',
					meta: {
						middleware: [domain, auth],
					},
				},
				{
					path: '/sponsorships/:id',
					component: Order,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/signage-orders/:id',
					component: Order,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/design', component: DesignDashboard,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.WF_PRINTER,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.DESIGNER,
						],
					},
				},
				{
					path: '/design/:mode/:id', component: DesignDashboardOrder,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.WF_PRINTER,
							roles.PUB_PRINTER,
							roles.DESIGNER,
						],
					},
				},
				
				{
					path: '/material', component: MaterialDashboard,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.WF_PRINTER,
							roles.FACILITATOR,
						],
					},
				},
				{
					path: '/material/queues',
					component: MaterialDashboardQueuesList,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.WF_PRINTER,
						],
						
					},
				},
				{
					path: '/material/queues/:id',
					component: MaterialDashboardOneQueue,
					name: 'MQ1',
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.WF_PRINTER,
						],
					},
				},
				{
					path: '/material/:mode/:id',
					component: DesignDashboardOrder,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.WF_PRINTER,
						],
					},
				},
				
				{
					path: '/material/materials',
					component: MaterialDashboardManageMaterials,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.WF_PRINTER,
						],
					},
				},
				{
					path: '/shipments', component: Shipments,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
                {
                    path: '/shipments-scanner/:type/:id', component: ShipmentScanner,
                    meta: {
                        middleware: [domain, auth, rolesCheck],
                        roles: [
                            roles.ADMIN,
                            roles.ACC_EXECUTIVE,
                            roles.PRODUCT_MANAGER,
                            roles.MANAGER,
                            roles.FACILITATOR,
                            roles.INSTALLER,
                        ],
                    },
                },
                {
                    path: '/shipments-scanner', component: ShipmentScanner,
                    meta: {
                        middleware: [domain, auth, rolesCheck],
                        roles: [
                            roles.ADMIN,
                            roles.ACC_EXECUTIVE,
                            roles.PRODUCT_MANAGER,
                            roles.MANAGER,
                            roles.FACILITATOR,
                            roles.INSTALLER,
                        ],
                    },
                },
				{
					path: '/shipments/:id', component: SingleShipment,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},

				{
					path: '/boxes/:uuid',
					component: SingleShipmentBox,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/shipment-create', component: ShipmentForm,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/shipment-edit/:id', component: ShipmentForm,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/shipments-dashboard', component: ShipmentDashboard,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				// {
				// 	path: '/shipments/box/:id/item',
				// 	component: ShipmentFeatureBox,
				// 	meta: {
				// 		middleware: [domain, auth, rolesCheck],
				// 		roles: [
				// 			roles.ADMIN,
				// 			roles.ACC_EXECUTIVE,
				// 			roles.PRODUCT_MANAGER,
				// 			roles.MANAGER,
				// 			roles.FACILITATOR,
				// 			roles.INSTALLER,
				// 		],
				// 	},
				// },
				{
					path: '/shipments/box/:id/item/:itemId',
					component: ShipmentDashboardItemPage,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/shipments/packing',
					component: ShipmentDashboardBocPackingPage,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/reports', component: ReportsDashboard,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/reports/billing', component: ReportsDashboardBilling,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/reports/billing/sponsorships', component: ReportsDashboardBillingSponsorships,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/reports/billing/signage', component: ReportsDashboardBillingSignage,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/user-management', component: UserManagement,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
						],
					},
				},

                {
                    path: '/user-settings', component: UserSettings,
                    meta: {
                        middleware: [domain, auth, rolesCheck],
                        roles: [
                            roles.ADMIN,
                            roles.ACC_EXECUTIVE,
                            roles.PRODUCT_MANAGER,
                            roles.MANAGER,
                        ],
                    },
                },

				{
					path: '/installer', component: InstallerDashboard,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
						
					},
				},
				
				{
					path: '/ready-to-install',
					component: InstallerDashboardReadyToInstall,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
						
					},
				},
				
				{
					path: '/installation-dashboard',
					component: InstallerDashboardMainFeature,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.WF_PRINTER,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
							roles.DESIGNER,
						],
					},
				},
				
				{
					path: '/installer-groups', component: InstallerGroupList,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-group-create',
					component: InstallerGroupCreate,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-group-edit/:id',
					component: InstallerGroupEdit,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-tickets-list',
					component: InstallerTicketsList,
					meta: {
						middleware: [domain, auth],
					},
				},
				{
					path: '/installer-walkthrough-list',
					component: InstallerWalkthroughList,
					meta: {
						middleware: [domain, auth],
					},
				},
				{
					path: '/installer-group-create/order-locations',
					component: OrderGroupLocations,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-walkthrough/:id',
					component: InstallerWalkthrough,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-ticket/:type/:id',
					component: InstallerTicketBlock,
					meta: {
						middleware: [auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer-group-locations/:id',
					component: InstallerGroupLocations,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
				{
					path: '/installer/:mode/:id',
					component: InstallerDashboardItemPage,
					meta: {
						middleware: [domain, auth, rolesCheck],
						roles: [
							roles.ADMIN,
							roles.ACC_EXECUTIVE,
							roles.PRODUCT_MANAGER,
							roles.MANAGER,
							roles.FACILITATOR,
							roles.INSTALLER,
						],
					},
				},
			],
		},
		
		{path: '/404', component: PageNotFound},
		{path: '*', redirect: '/404'},
	],
});

router.beforeEach((to, from, next) => {
	
	if (!to.meta.middleware) {
		return next();
	}
	
	const middleware = to.meta.middleware;
	
	const context = {
		to,
		from,
		next,
		store,
	};
	
	return middleware[0]({
		...context,
		nextMiddleware: middlewarePipeline(context, middleware, 1),
	});
});

export default router;