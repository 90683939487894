<template>
    <div class="component-wrap">

        <AppContentHeader headline="Materials dashboard">
            <div class="content-header__actions">

                <router-link tag="md-button" to="/material/materials" class="md-primary md-raised">Manage materials
                </router-link>
                <router-link tag="md-button" to="/material/queues" class="md-primary md-raised">Check queues
                </router-link>

                <md-button class="md-primary md-raised" @click="downloadPrintTickets()">Download print tickets
                </md-button>

                <md-button class="md-primary md-raised" @click="excelExport()">Excel Export
                </md-button>

            </div>

        </AppContentHeader>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <div class="main-hdr">
            <material-dashboard-search ref="materialSearch" @updateFilterResults="updateSearchFilters" @setResultsOrders="setResultsOrders"
                                       @searchInput="inputSearch"/>
        </div>

        <material-dashboard-table ref="matDashboard" v-if="filteredOrders.length && !loadingSpinner"
                                  :searchStr="searchString" :orderType="orderType" :materials-prop="filteredOrders"
                                  @getAllOrders="emitSearchFunction"
                                  @searchInput="inputSearch"
                                  @reOrder="reOrderColumn"></material-dashboard-table>

        <AppEmptyState message="There is no ready orders to display." v-if="!filteredOrders.length && !loadingSpinner"/>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{ snackbar.message }}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->

    </div>
</template>

<script>
import MaterialDashboardTable from '../../components/MaterialDashboard/MaterialDashboardTable.vue';
import MaterialDashboardSearch from '../../components/MaterialDashboard/MaterialDashboardSearch';
import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin';

export default {
    name: 'MaterialDashboard',
    mixins: [DeepFilterMixin, InitializeOrderMixin],
    data: function() {
        return {
            orders: [],
            snackbar: {
                show: false,
                message: '',
                duration: 4000,
            },
            orderType: '',
            itemsToShow: 9,
            scrollPosition: 0,
            ordersToDisplay: [],
            loadingSpinner: false,
            filter: '',
            previousSeason: false,
            cancelledOrders: false,
            showAllOrders: false,
            filteredOrders: [],
            searchString: '',
            initialLoad: true,
            searchFilter: {
                search: '',
                media_type: 'sponsorship',
                size: '',
                status_id: '',
                material_id: '',
            }
        };
    },
    components: {
        MaterialDashboardSearch,
        MaterialDashboardTable,
    },
    created() {
        this.loadData().then(() => {
            this.filter = this.$store.getters['getMaterialFilter'];
            this.initialLoad = false;
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('saveMaterialFilter', this.filter);
        next();
    },
    watch: {
        showAllOrders(value) {

            // this.orders = [];
            let requestString = {};

            if (value) {
                requestString = '/unset-material-orders';
            } else {
                requestString = '/unset-material-orders?ready_to_print=true';
            }

            this.getOrders(requestString);
        },
    },
    methods: {

        excelExport() {

            let data = {params: {...this.searchFilter, previous_season: this.previousSeason, show_cancelled_orders: this.cancelledOrders}};

            console.log(this.searchFilter);

            if(this.orderType === 'signage'){
                this.$axios.get('signage-orders/export', data).then(response => {
                    console.log('Done')
                    window.open(response.data, '_blank');

                }).catch(() => {
                    this.showSnackbar('We couldnt export the signage table.');
                });
            }
            else{
                this.$axios.get('sponsorships/export', data).then(response => {
                    console.log('Done')
                    window.open(response.data, '_blank');
                }).catch(() => {
                    this.showSnackbar('We couldnt export the sponsorships table.');
                });
            }
        },

        updateSearchFilters(filtersObj){
            this.searchFilter = filtersObj.filters;
            this.previousSeason = filtersObj.season;
            this.cancelledOrders = filtersObj.show_cancelled_orders;
        },

        downloadPrintTickets() {
            if (this.$refs.matDashboard) {
                this.$refs.matDashboard.getAllChecked();
            } else {
                this.showSnackbar('There are no orders yet.');
            }
        },

        emitSearchFunction() {
            this.$refs.materialSearch.search(false);
        },
        reOrderColumn(fieldToReorder) {
            this.filteredOrders.sort((a, b) => (a[fieldToReorder] > b[fieldToReorder]) ? 1 : -1);
        },

        inputSearch(searchStr) {


            console.log('Search String Input2');
            console.log(searchStr)

            let _this = this;
            this.searchString = searchStr;

            setTimeout(function() {
                _this.filteredOrders = _this.orders.filter(x => _this.filterFunction(x, searchStr));
            }, 1200);
        },

        setResultsOrders(orders) {

            let tempOrders = [];
            orders.forEach(el => tempOrders.push(this.initializeOrder(el)));

            this.orders = tempOrders;
            this.filteredOrders = tempOrders;

            this.orderType = this.orders[0].mediaType;
            console.log(this.orderType);

            if (this.initialLoad === false){

                this.$refs.matDashboard.updateOrders(this.filteredOrders, this.orderType);
            }
        },

        showSnackbar(errorMessage = null) {
            this.snackbar.message = errorMessage ? errorMessage : 'Material successfully set';
            this.snackbar.show = true;
        },

        async getOrders() {
            this.loadingSpinner = true;
            try {
                const sponsorships = (await this.$axios.get('/sponsorships')).data.map(x => {
                    return {
                        ...x,
                        index: x.sponsorships_id,
                    };
                });
                // const signage = (await this.$axios.get('/signage-orders')).data.map(x => {
                //     return {
                //         ...x,
                //         index: x.id,
                //     };
                // });
                // this.orders = [...sponsorships];
                // this.filteredOrders = [...sponsorships];
                this.setResultsOrders([...sponsorships]);

                this.loadingSpinner = false;
            } catch (err) {
                this.loadingSpinner = false;
                this.snackbar.message = err.message;
                this.snackbar.show = true;
            }
        },

        async loadData() {

            await this.getMaterials();
            await this.getOrders();
        },

        getMaterials() {
            return this.$store.dispatch('materials/getMaterials');
        },
    },
};
</script>

<style lang="scss" scoped>
@include scroller-styles;

.toggle-label {
    margin-right: 1.2rem !important;
    font-size: 1.5rem;
    font-weight: bold;
}

.main-hdr {
    padding-top: 33px;
}
</style>